import { useMemo } from 'react';
import Select, { type MenuPosition, type SingleValue } from 'react-select';

import {
  EnumsOrgSubscriptionPlan,
  EnumsOrgSubscriptionStatus,
} from '@lp-lib/api-service-client/public';

import { type Organization } from '../../types';
import { err2s } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { Loading } from '../Loading';
import { useOrganizations } from './hooks/organization';

export const FAKE_ORGANIZATION: Organization = {
  id: '00000000-0000-0000-0000-000000000000',
  name: 'Fake Org',
  organizersCount: 0,
  pairingDisabledParticipantsCount: 0,
  canTrial: false,
  createdAt: '',
  updatedAt: '',
  logo: null,
  usesSlack: true,
  maxSize: 999999,
  connection: null,
  settings: null,
  subscription: {
    plan: EnumsOrgSubscriptionPlan.OrgSubscriptionPlanFree,
    status: EnumsOrgSubscriptionStatus.OrgSubscriptionStatusActive,
    customerId: null,
    features: [],
  },
  purchase: null,
};

export function OrganizationSelect(props: {
  orgId: string | null;
  onChange?: (org: Organization | null) => void;
  placeholder?: string;
  isClearable?: boolean;
  className?: string;
  isDisabled?: boolean;
  fakeOrgLabel?: string;
  noFakeOrg?: boolean;
  menuPosition?: MenuPosition;
}): JSX.Element {
  const {
    orgId,
    onChange,
    placeholder = 'Organization',
    isClearable,
    fakeOrgLabel,
    noFakeOrg,
    menuPosition,
  } = props;

  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<Organization | null>>({
        override: {
          control: {
            height: '40px',
          },
        },
      }),
    []
  );
  const { data: organizations, isLoading, error } = useOrganizations();

  const options = useMemo(() => {
    if (!organizations) return [];

    const opts: Option<Organization | null>[] = organizations.map((o) => ({
      label: o.name,
      value: o,
    }));
    if (!noFakeOrg) {
      opts.unshift({ label: fakeOrgLabel || 'N/A', value: FAKE_ORGANIZATION });
    }
    return opts;
  }, [fakeOrgLabel, noFakeOrg, organizations]);

  const handleOrganizationChange = (
    option: SingleValue<Option<Organization | null>>
  ) => {
    if (!onChange) return;
    onChange(option?.value || null);
  };

  if (isLoading) {
    return <Loading containerClassName='justify-start' />;
  }

  if (error) {
    return <div className='text-3xs text-red-002'>{err2s(error)}</div>;
  }

  return (
    <Select<Option<Organization | null>>
      isDisabled={props.isDisabled ?? false}
      className={props.className ?? 'w-56'}
      classNamePrefix='select-box-v2'
      options={options}
      onChange={handleOrganizationChange}
      styles={styles}
      formatOptionLabel={(opt) => (
        <span className='text-white'>{opt.label}</span>
      )}
      value={options.find((o) => o.value?.id === orgId) ?? null}
      placeholder={placeholder}
      isClearable={isClearable}
      menuPosition={menuPosition}
    />
  );
}
